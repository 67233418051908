<template>
  <div>
    <div
      show
      variant="light"
      class="
        alert alert-custom alert-white alert-shadow
        fade
        show
        gutter-b
        justify-content-end
      "
    >
      <b-col md="4">
        <b-col md="9"><label :for="`date`">Chọn ngân hàng:</label></b-col>
        <b-col md="9">
          <div>
            <div class="text-left" v-if="Object.keys(listBank).length == 0">
              <b-spinner class="sm-spinner" variant="primary"></b-spinner>
            </div>
          </div>
          <b-form-select
            v-model="bankCode"
            v-if="Object.keys(listBank).length > 0"
            @change="getBankTemplate()"
          >
            <option selected disabled>Chọn Ngân hàng</option>
            <option
              v-for="(item, bankCode) in listBank"
              :value="bankCode"
              :key="bankCode"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-col>
      </b-col>
      <b-col md="4">
        <b-container fluid>
          <b-col md="9"><label :for="`date`">Chọn ngày:</label></b-col>
          <b-col md="9">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="date"
            ></b-form-input>
          </b-col>
        </b-container>
      </b-col>
    </div>

    <b-card-text v-if="errorMessage" align="center" class="text-danger">{{
      errorMessage
    }}</b-card-text>
    <VietinbankReconcile
      v-if="bankCode == 'CTG'"
      :date="date"
      :bankCode="bankCode"
    ></VietinbankReconcile>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import BankConfig from "@/core/config/bank.config";
import VietinbankReconcile from "@/view/components/reconcile/Vietinbank";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const BankRepository = RepositoryFactory.get("bank");

export default {
  name: "Reconcile",
  mixins: [Common],
  components: {
    VietinbankReconcile
  },
  data() {
    return {
      listBank: {},
      bankCode: "",
      date: this.getCurrentDay(),
      errorMessage: "Vui lòng chọn ngân hàng và thời gian"
    };
  },
  methods: {
    getListBank() {
      return BankRepository.getListBank(BankConfig.TYPE_LINK_PAYGATE)
        .then(response => {
          if (response.status != 200 || response.data.error_code == 1) {
            return (this.errorMessage = "Có lỗi khi lấy thông tin ngân hàng");
          }
          this.listBank = response.data.data;
        })
        .catch(() => {
          return (this.errorMessage = "Có lỗi khi lấy thông tin ngân hàng");
        });
    },

    getBankTemplate() {
      if (this.bankCode != "" && this.date) {
        this.errorMessage = null;
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  created() {
    this.getListBank();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thông tin đối soát" }]);
  }
};
</script>
